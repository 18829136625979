<template>
  <div class="container">
    <div class="wrapper">
      <div class="title">文字识别朗读机</div>
      <el-input type="textarea" style="width" :rows="4" placeholder="请输入内容" v-model="speakText"> </el-input>
      <div class="import-inout-wrapper">
        <div class="import-inout">
          <input type="file" id="fileInput" value="导入文件" accept=".txt, .rtf" @input="handleInput" />
        </div>
      </div>
      <el-progress style="margin-top: 10px" :percentage="percentage"></el-progress>
      <div class="options">
        <el-button type="primary" :icon="playIcon" circle @click="handlePlay"></el-button>
      </div>
    </div>
  </div>
</template>

<script>
// 导入 RTFJS 模块
import { EMFJS, RTFJS, WMFJS } from 'rtf.js'
export default {
  name: 'HomeView',
  components: {},
  data() {
    return {
      speakText: '',
      playIcon: 'el-icon-video-play',
      percentage: 0
    }
  },
  mounted() {
    this.initImport()
  },
  beforeDestroy() {
    this.handleClean()
  },
  methods: {
    // 初始化导入
    initImport() {
      const fileInput = document.getElementById('fileInput')
      const _this = this
      fileInput.addEventListener('change', function (event) {
        const file = event.target.files[0]
        if (file) {
          const reader = new FileReader()
          reader.onload = function (e) {
            const content = e.target.result
            RTFJS.loggingEnabled(false)
            WMFJS.loggingEnabled(false)
            EMFJS.loggingEnabled(false)
            const doc = new RTFJS.Document(_this.stringToArrayBuffer(content))
            doc
              .render()
              .then(function (htmlElements) {
                _this.speakText = htmlElements.map(el => el.innerText).join('')
              })
              .catch(error => console.error(error))
          }
          reader.readAsText(file, 'UTF-8')
        }
      })
    },
    handleInput(event) {
      console.log('event', event)
    },
    handlePlay() {
      // 没有文本时直接 return
      if (!this.speakText) return
      if (!speechSynthesis.speaking) {
        // 如果播放队列没有语音
        this.playIcon = 'el-icon-video-pause'
        let ssu = new SpeechSynthesisUtterance(this.speakText)
        // 语音播报开始回调
        ssu.onstart = event => this.onstart(event)
        // 语音播报结束回调
        ssu.onend = event => this.onend(event)
        // 语音播报出错回调
        ssu.onerror = event => this.onerror(event)
        // 语音播报读到标记文本时的回调
        ssu.onmark = event => this.onmark(event)
        // 语音播报暂停回调
        ssu.onpause = event => this.onpause(event)
        // 语音播报取消暂停回调
        ssu.onresume = event => this.onresume(event)
        // 语音播报取消暂停回调
        ssu.onboundary = event => this.onboundary(event)

        window.speechSynthesis.speak(ssu)
      } else if (this.playIcon === 'el-icon-video-pause') {
        // 如果有正在播放的语音
        this.playIcon = 'el-icon-video-play'
        window.speechSynthesis.pause()
      } else if (this.playIcon === 'el-icon-video-play') {
        // 如果有正在暂停的语音
        this.playIcon = 'el-icon-video-pause'
        window.speechSynthesis.resume()
      }
    },
    // 结束回调
    onend() {
      this.playIcon = 'el-icon-video-play'
      this.percentage = 100
      this.handleClean()
    },
    // 错误回调
    onerror(e) {
      console.log('onerror', e)
    },
    // 读到标记文字回调
    onmark(e) {
      console.log('onmark', e)
    },
    // 暂停回调
    onpause(e) {
      console.log('onpause', e)
    },
    // 恢复回调
    onresume(e) {
      console.log('onresume', e)
    },
    // 开始回调
    onstart(e) {
      console.log('onstart', e)
    },
    // 进度回调
    onboundary(e) {
      this.percentage = Math.ceil((e.charIndex / this.speakText.length) * 100)
    },
    handleClean() {
      window.speechSynthesis.cancel()
    },
    stringToArrayBuffer(string) {
      const buffer = new ArrayBuffer(string.length)
      const bufferView = new Uint8Array(buffer)
      for (let i = 0; i < string.length; i++) {
        bufferView[i] = string.charCodeAt(i)
      }
      return buffer
    }
  }
}
</script>
<style scoped lang="scss">
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  .wrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    .title {
      margin-bottom: 10px;
      font-size: 22px;
      font-family: 'Courier New', Courier, monospace;
      color: #1677ff;
    }
    .import-inout-wrapper {
      width: 100%;
      margin-top: 20px;
      .import-inout {
        width: 20%;
        #fileInput {
        }
      }
    }
    .options {
      margin-top: 10px;
    }
  }
}
</style>
